<template>
  <v-app class="app-wrapper">
     <v-main class="main-bg">       
          <router-view/>
     </v-main>
  </v-app>
</template>

<script>
import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";

export default {
   name: 'App',
   mounted() {
            FingerprintJS.load({
            token: "s9yHH05h3PT16F7MpVzP",
        })
        .then((fp) => fp.get())
        .then((result) => {
            console.log('VisitorId: ', result.visitorId);
        });


        var s = document.createElement('script');    
        s.type = 'text/javascript';    
        s.async = true;   
        s.src = 'https://app.termly.io/embed.min.js';    
        s.id = 'a5811b7b-2dc1-4150-9f52-56a158fb1acb';    
        s.setAttribute("data-name", "termly-embed-banner");    
        var x = document.getElementsByTagName('script')[0];    
        x.parentNode.insertBefore(s, x);  
    }, 
};
</script>

<style>

.app-wrapper{
}
.nav-drawer {
     background: #2b383e !important;
     width: 240px !important;
     border-right1:1px solid #e9e9e9 !important;
     box-shadow1: 0 0 !important;
     background:#f1f5fe !important;
          background1: #f1f1ff !important;

     background1: #605bff !important;
     border-right1:1px solid #cccccc !important;
     box-shadow1: 0 0 2px #cccccc !important;
     background1: #6343a4 !important;
     background1: #cfeeff !important;
          background1:#d2f1e5 !important;
          background1: #ffffff !important;
               background1:#3f3957 !important;


}
.v-navigation-drawer__border{
     border:0 solid !important;
     width:0px !important;
}

.app-bar {
     background: #f5f8fb !important;
     background:#f1f5fe !important;
     background1: #f5f8fb !important;
     background1: #f2f3f9 !important;
     background: #ffffff !important;
     background1: #f5f6fa !important;
     background1: #f5f8fb !important;
     height: 55px !important;
     border-bottom:0px solid #ff0000 !important;
     background:#c6e3fd !important;          
     
}
.d-icon{
  float: left !important;
  color:#11a1fd !important; 
  background1:#11a1fd;  
  margin-left:40px;
}
.brand{
     background:#ffffff !important;
     padding:0 !important;
     width:240px !important;
     display: inline-flex !important;
     margin-left:-16px !important;
     background1: #605bff !important;
     background1: #6343a4 !important;
     height: 63px;
     color:#222222 !important; 
     padding-top:10px !important;
               background:#f1f5fe !important;
                    background:#f1f5fe !important;
                    color1:#5e79fd !important;
                    color:#ffffff !important;
     padding-left:0 !important; 
     font-weight: 600 !important;;


}
.top-title{
  float: left;
}
.main-bg{
     background: #f5f8fb !important;
     background: #f2f3f9 !important;
     background: #ffffff !important;
          background:#f1f1ff !important;
               background: #f9fafc !important;


          background: #f5f6fa !important;
     background: #f5f8fb !important;
     background:#c6e3fd !important;          

}

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled){
   color:#012a42 !important;
   color:#949393 !important;
   color:#7f84a4 !important;
   color:#bfbfc0 !important;
   color:#aa8aef !important;
   color:#8f9091 !important;
   color:#92a6ba !important  
}
.v-list-item .v-list-item__title, .v-list-item .v-list-item__subtitle {
     font-size:14px !important;
}
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) i{
   color:#949393 !important;
   margin-right:0px !important;
   color:#7081b9 !important;
   color:#bfbfc0 !important;
   color:#aa8aef !important;
      color:#8f9091 !important;
      margin-top:0px !important;
         color:#7e8fa9 !important  


}
.theme--light.v-list-item--active:hover::before, .theme--light.v-list-item--active::before {
     opacity: 0 !important;
     background:#24aeee !important;
     color:#ffffff !important;
}
.theme--light.v-list-item--active, .theme--light.v-list-item--active {
     opacity: 1 !important;
     background1:#ededfd !important;
     color1:#e0e4fc !important;
     font-weight: 500;
     border-radius: 0;
     border-right:4px solid #282586 ;
     width:100% !important;
     margin-left:3px;
     min-width:230px;
     padding-left:5px !important;
     color:#4e56a9 !important;
     background1:#e6e9fe !important;
     border-right:4px solid #5e79fd ;
     border-radius1: 0 5px 5px 0;
     color:#0f2d69 !important;
     background1:#e5e5e6 !important;
 color:#ffffff !important;
 background:rgba(0,0,0,0.1)

}
.v-list .v-list-item--active .v-icon{
 color:#4e56a9 !important;
 margin-top:0 !important;
 color:#5e79fd !important;
}

.v-list-item{
     height: 30px !important;
     min-height: 35px !important;;
}
.v-list-item__icon{margin-right:15px !important;height:0px !important}
.menu-icon{
     font-size:20px !important;
     font-weight: 100 !important;
}

#SidebarNavigation{ 
     margin-top:50px;
}

.search-control{
     margin-left:50px;
}
.search-control input {
     border1s:1px solid #cccccc;
     border-radius: 30px;
     min-width:100%;
     width:500px;
     padding:7px 20px;
     background: #f5f8fb !important;
}
.search-control input:focus {
   
}

.brand,.nav-drawer{
     background1:#0c2556 !important;
     background1:#e3fbff !important;
     background: #f5f8fb !important;
     border-right1:1px solid #e6e6e6;
     box-shadow2: 0 0 2px #dddddd !important;
          background:#123858 !important;          

}
.brand{
     background:#ffffff !important;
     color:#5e79fd !important;
          color:#051e97 !important;
          background:#062844 !important;
         color:#ffffffcc !important;  


}

.inner-container {
    margin: 60px 30px 30px 30px;
}

.list-title {
    color: #333333 !important;
    font-weight: 600;
    text-decoration: none !important;
}

.widget {
    box-shadow: 0 0 3px #cccccc;
    background: #f5f8fb;
    padding: 20px;
    border-radius: 5px;
    border: 8px solid #ffffff;
    background: white !important;
}

.list table {
    background1: #f2f3f9 !important;
    border-collapse: separate !important;
    border-spacing: 0 0px !important;
    background: #f5f8fb;
    background: #ffffff !important;
}

.list table tbody tr td:first-child {
    border-radius: 4px 0 0 4px !important;
}

.list table tbody tr td:last-child {
    border-radius: 0 4px 4px 0 !important;
}

.list table tbody tr td {
    background: #f2f3f9 !important;
    background: #ffffff !important;
    color: #797878 !important;
    font-size: 13px !important;
    border-bottom: 1px dashed #dddddd !important;
}

.list table tbody tr:last-child td {
    background: #f2f3f9 !important;
    background: #ffffff !important;
    color: #797878 !important;
    font-size: 13px !important;
    border-bottom: 0px dashed #dddddd !important;
}


.search-box {
    max-width: 400px !important;
}
.list table th {
        margin-top: 10px !important;

    background: #ffffff;
    color: #333333 !important;
    background1: #f5f8fb !important;
}

.list-rate table tbody tr td input {
    background: #f2f3f9 !important;
    background: #ffffff !important;
    color: #797878 !important;
    font-size: 13px !important;
    border: 0px !important;
}

.list-rate table tbody tr td {
    background: #f2f3f9 !important;
    background: #ffffff !important;
    color: #797878 !important;
    border-bottom: 0px !important;
}

.status-box {
    padding: 6px 12px !important;
    border-radius: 20px;
    font-size: 13px !important;
    width: 100px !important;
    float: left !important;
    text-align: center;
}

.status-completed {
    color: #1c8f05;
    border: 1px solid #1c8f05;
}

.status-in-progress {
    color: #e98d03;
    border: 1px solid #e98d03;
}

.status-draft {
    color: #1f88c4;
    border: 1px solid #1f88c4;
}

.status-withdrawn {
    color: #b93f0e;
    border: 1px solid #b93f0e;
}

.list-ul {
    width1: 100%;
    border-bottom1: 1px solid #cccccc;
    padding: 0 !important;
    padding-bottom: 5px !important;
    margin: 20px 0 20px 0 !important;
}

.list-ul li {
    display: inline;
    list-style: none;
    margin-right: 10px;
    padding-bottom: 5px !important;
}

.list-ul li a {
    text-decoration: none;
    font-size: 14px;
    color: #666666;
    padding-bottom: 6px;
    font-weight: 400;
    padding: 7px 14px;
}

.list-ul li a.normal-tab {
    text-decoration: none;
    font-size: 14px;
    color: #666666;
    padding-bottom: 6px;
    font-weight: 400;
    padding: 7px 14px;
}

.list-ul li a.active-tab {
    border-bottom1: 3px solid #ff6d39;
    color: #dbdadd;
    font-weight: 500;
    background1: #ff6d39;
    background: #5e79fd;
    border-radius: 30px;
}

.v-data-table-header th {
    white-space: nowrap;
}

.plan {
    margin: 10px;
}

.planborder {
    border: 4px solid #ff9900 !important;
    margin: 10px;

}
.iv-btn{
     background:transparent !important;
     box-shadow: none !important;
     width:50px;
     float:right;
}
.iv-btn .btn-icon{
     color:#ffd000 !important;
     font-size:40px !important;
     float:right;
}

</style>